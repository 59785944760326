import { useEffect, useState, useContext } from 'react';
import { collection, getDocs } from 'firebase/firestore';
// import { getAuth } from 'firebase/auth';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Heading,
  HStack,
  Box,
  Button,
  Divider,
  Text,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
} from '@chakra-ui/react';
import moment from 'moment';

import { db } from '../constants';
import DataContext from '../context/DataContext';
import AppBody from '../components/AppBody';
import AddUserModal from '../components/AddUserModal';
import SpinnerPage from '../components/SpinnerPage';
import BackButton from '../components/BackButton';

function Users() {
  const toast = useToast();

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userData } = useContext(DataContext);
  const { partnerUid, admin, businessName, memberUid } = userData;

  const sortArray = array => {
    // ascending order of createdAt
    const sortedArray = array.sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
    return sortedArray;
  };

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const memberUsers = [];
        const colRef = collection(db, 'partnerMembers');
        const docsSnap = await getDocs(colRef);
        if (docsSnap.docs.length > 0) {
          docsSnap.forEach(doc => {
            // if any member's partnerUid matches
            const member = doc.data();

            if (member?.partnerUid === partnerUid) {
              memberUsers.push(member);
            }
          });
        }

        setUsers(sortArray(memberUsers));
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast({
          description: 'fetch error',
          status: 'error',
        });
      }
    };

    fetchListings();
  }, [toast, partnerUid]);



  const fetchListings = async () => {
    // console.log('fetching')
    try {
      setUsers(null);
      const memberUsers = [];
      const colRef = collection(db, 'partnerMembers');
      const docsSnap = await getDocs(colRef);
      if (docsSnap.docs.length > 0) {
        docsSnap.forEach(doc => {
          // if any member's partnerUid matches
          const member = doc.data();

          if (member?.partnerUid === partnerUid) {
            memberUsers.push(member);
          }
        });
      }

      setUsers(sortArray(memberUsers));
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast({
        description: 'fetch error',
        status: 'error',
      });
    }
  };

  const onCloseModal = e => {

    onClose(e);
    fetchListings();
    
  }

  const renderUsers = () => {
    if (loading) {
      return <SpinnerPage />;
    }
    if (!users) {
      return <SpinnerPage />;
    }
    return (
      <>
        <Accordion allowToggle>
          {users.map(user => (
            <AccordionItem isDisabled={!admin} key={user.memberUid}>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <Text fontSize="lg" as="b">
                    {user.firstName} {user.lastName}
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4} bgColor="gray.50">

                <HStack justify="space-between">
                  <Text>Title:</Text>
                  <Text>{user.title || '-'}</Text>
                </HStack>
                <Divider my={2} />
                <HStack justify="space-between">
                  <Text>Email:</Text>
                  <Text>{user.email || '-'}</Text>
                </HStack>
                <Divider my={2} />
                <HStack justify="space-between">
                  <Text>Business Phone:</Text>
                  <Text>{user.phone || '-'}</Text>
                </HStack>
                <Divider my={2} />
                <HStack justify="space-between">
                  <Text>Cell:</Text>
                  <Text>{user.cell || '-'}</Text>
                </HStack>
                <Divider my={2} />

                <HStack justify="space-between">
                  <Text>Created:</Text>
                  <Text>
                    {moment(
                      user.createdAt,
                      'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                    ).format('MMM D, YYYY - h:mma')}
                  </Text>
                </HStack>
                <Divider my={2} />
                <HStack justify="space-between">
                  <Text>Last Login:</Text>
                  <Text>
                    {user.lastLogin ? moment(
                      user.lastLogin,
                      'ddd MMM DD YYYY kk:mm:ss Z ZZ'
                    ).format('MMM D, YYYY - h:mma') : '-'}
                  </Text>
                </HStack>
                <Divider my={2} />
                <HStack justify="space-between">
                  <Text>Active {user.active ? 'true' : 'false'}</Text>
                  <Text>Admin {user.admin ? 'true' : 'false'}</Text>
                </HStack>

                <HStack justify="space-between" mt={2}>
                  {user.admin && user.active && user.memberUid !== memberUid && <Button size='sm' variant='outline' colorScheme='red' disabled>Deactivate</Button>}
                  {user.admin && !user.active && user.memberUid !== memberUid && <Button size='sm' variant='outline' colorScheme='blue' disabled>Activate</Button>}
                  {!user.admin && user.active && <Button size='sm' variant='outline' colorScheme='red' disabled>Deactivate</Button>}
                  {!user.admin && !user.active && <Button size='sm' variant='outline'colorScheme='blue' disabled>Activate</Button>}
                  {!user.admin && user.active && <Button size='sm' variant='outline' colorScheme='blue' disabled>Make admin</Button>}
                </HStack>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </>
    );
  };

  return (
    <>
    <Modal isOpen={isOpen} onClose={e => onCloseModal(e)}>
        <ModalOverlay />
        <ModalContent>
          <AddUserModal partnerUid={partnerUid} businessName={businessName}/>
          <ModalFooter>
            <Button
              w="full"
              variant="link"
              colorScheme="primary"
              onClick={e => onCloseModal(e)}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AppBody>
      <BackButton />
        <HStack justify="space-between">
          <Heading size="md">Users {!admin && ' (read-only)'}</Heading>
          {admin && <Button colorScheme="primary" onClick={onOpen}>Add User +</Button>}
        </HStack>
        {/* <Text>
          With access to login
        </Text> */}
        <Divider borderColor="primary.500" borderWidth={1} my={2} />

        {renderUsers()}
      </AppBody>
    </>
  );
}

export default Users;
