import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Button,
  Divider,
  Image,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Center,
  Text,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon, EmailIcon } from '@chakra-ui/icons';
import {
  BsPower,
  BsChevronLeft,
  BsInfoCircle,
  BsHouse,
  BsSearch,
  BsListUl,
} from 'react-icons/bs';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';

import InviteModal from '../components/InviteModal';
import googleImage from '../assets/google_icon.png';
import { isDev, deployed, LINK } from '../constants';
import { db } from '../constants';

function Header(props) {
  const [userData, setUserData] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const auth = getAuth();

  const toast = useToast();
  // const auth = getAuth();
  // const params = useParams();
  const navigate = useNavigate();

  // need to useEffect here, cant use DataContext because
  // this is not a child not wrapped in App.js
  useEffect(() => {
    if (!auth.currentUser) return;

    // const getPartnerUid = async () => {
    //   const docRef = doc(db, 'partnerMembers', auth.currentUser.uid);
    //   const docSnap = await getDoc(docRef);
    //   const memberData = docSnap.data();
    //   return memberData.partnerUid;
    // };

    // const partnerUid = getPartnerUid();

    const unsubscribe = onSnapshot(
      doc(db, 'partnerMembers', auth.currentUser.uid),
      doc => {
        setUserData(doc.data());
      }
    );
    return unsubscribe;
  }, [auth]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        const docRef = doc(db, 'partnerMembers', auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // console.log('user in onAuthStateChanged', user);
          // console.log('user found user', auth.currentUser);
          // console.log('data', docSnap.data());
          setUserData(docSnap.data());
          setLoggedIn(true);
        } else {
          // console.log("first login")
          setLoggedIn(true);
        }
        // setCurrentUser(user);
      } else {
        // console.log('there is no user');
        setUserData(null);
        setLoggedIn(false);
      }
      //   setIsLoading(false);
      setCheckingStatus(false);
    });
    return unsubscribe;
  }, [auth]);

  const onLogout = () => {
    if (
      !window.confirm('Logout as ' + userData.email + ', are you sure?')
    ) {
      return;
    }
    // console.log('auth', auth);
    // auth.signOut();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        toast({ description: 'Logged Out' });
        navigate('/login');
        return;
      })
      .catch(error => {
        console.log('logout error')
        console.error(error);
        toast({ description: 'Log out error' });
        // An error happened.
      });
  };

  // const bgColor = useColorModeValue('gray.100', 'gray.900');

  if (checkingStatus) {
    return (
      <>
        <Box
          pt={3}
          // bg={bgColor}
          px={2}
          h={'60px'}
          // borderTop={isDev ? '2px' : '0px'}
          // borderTop={'2px'}
          // borderTopColor="primary.500"
          // borderBottom="1px"
          // bg='red'
        >
          {/* <Flex h={16}> */}

          <Box>
            <Button
              leftIcon={props?.back && <BsChevronLeft />}
              // pl={1}
              pl={props?.back ? 1 : 5}
              onClick={() => navigate('/')}
              variant="ghost"
            >
              <Text
                fontSize="xl"
                borderBottom="2px"
                borderBottomColor="primary.500"
              >
                {props?.back ? 'BACK' : 'EEZE'}
              </Text>
            </Button>
          </Box>
          {/* </Flex> */}
        </Box>
      </>
    );
  }
  // not logged in
  if (!loggedIn) {
    return (
      <>
        <Box
          // bg={bgColor}
          px={2}
          h={'60px'}
          borderTop={isDev ? '2px' : '0px'}
          borderTopColor="primary.500"
          // borderBottom="1px"
          // borderBottomColor="lightgray"
        >
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            {/* LEFT */}
            <Box>
              <Button pl={1} onClick={() => navigate('/')} variant="ghost">
                <Text
                  fontSize="xl"
                  borderBottom="2px"
                  borderBottomColor="primary.500"
                >
                  EEZE
                </Text>
              </Button>
            </Box>

            {/* RIGHT */}
            <Flex alignItems={'center'}>
              <Button
                colorScheme="primary"
                // size="sm"
                variant="ghost"
                onClick={() => navigate('/login')}
              >
                Login
              </Button>
              <Button
                colorScheme="primary"
                // size="sm"
                onClick={() => navigate('/signup')}
              >
                Sign Up
              </Button>
            </Flex>
          </Flex>
        </Box>
      </>
    );
  }

  // logged in
  return (
    <>
      <Modal onClose={onClose} size={'xl'} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <InviteModal />
          <ModalFooter>
            <Button
              onClick={onClose}
              w="full"
              colorScheme="primary"
              variant="outline"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box
        // bg={bgColor}
        px={2}
        h={'60px'}
        // borderTop={isDev ? '2px' : '0px'}
        // borderTopColor="primary.500"
      >
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          {/* LEFT */}

          <Box>
            <Button
              leftIcon={props?.back && <BsChevronLeft />}
              pl={props?.back ? 1 : 5}
              onClick={() => (props?.previousScreen ? navigate(-1) : navigate('/'))}
              variant="ghost"
            >
              <Text
                fontSize="xl"
                borderBottom="2px"
                borderBottomColor="primary.500"
              >
                {props?.back ? 'BACK' : 'EEZE'}
              </Text>
            </Button>
          </Box>

          {/* RIGHT */}
          {/* <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                as={IconButton}
                // style={{
                //   borderColor: '#B500A9',
                //   color: '#B500A9',
                //   backgroundColor: 'white',
                // }}
                style={{
                  borderColor: 'primary.500',
                  color: 'primary.500',
                  backgroundColor: 'white',
                }}
                aria-label="Options"
                icon={<HamburgerIcon />}
                variant="outline"
              />
              <MenuList zIndex={10}>
                <Center>
                  <Text>{userData?.businessName}</Text>
                </Center>
                <Center>
                  <Text as="i" color="gray" fontSize="sm">
                    {userData?.email}
                  </Text>
                </Center>

                <Divider borderWidth={1} />
                <MenuItem>
                  <Button
                    onClick={() => navigate('/')}
                    variant="ghost"
                    w="full"
                    as={'a'}
                    justifyContent="flex-start"
                    leftIcon={<BsHouse />}
                  >
                    Home
                  </Button>
                </MenuItem>

                <MenuItem>
                  <Button
                    onClick={() => navigate('/referrals')}
                    w="full"
                    variant="ghost"
                    justifyContent="flex-start"
                    as={'a'}
                    leftIcon={<BsListUl />}
                  >
                    View Referrals
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    onClick={onOpen}
                    w="full"
                    variant="ghost"
                    justifyContent="flex-start"
                    as={'a'}
                    leftIcon={<EmailIcon />}
                  >
                    Invite Customer
                  </Button>
                </MenuItem>
                <MenuItem
                  // isDisabled
                  // isDisabled={!userData?.approved}
                >
                  <Button
                    onClick={() => navigate('/listings')}
                    w="full"
                    variant="ghost"
                    justifyContent="flex-start"
                    // disabled
                    // disabled={!userData?.approved}
                    as={'a'}
                    leftIcon={<BsSearch />}
                  >
                    Search Listings
                  </Button>
                </MenuItem>
                <MenuItem mt='40px'>
                  <Button
                    onClick={() => navigate('/profile')}
                    w="full"
                    variant="ghost"
                    justifyContent="flex-start"
                    as={'a'}
                    leftIcon={<BsInfoCircle />}
                  >
                    Profile
                  </Button>
                </MenuItem>
                <Divider borderWidth={1} />
                <MenuItem>
                  <Button
                    onClick={onLogout}
                    w="full"
                    variant="ghost"
                    justifyContent="flex-start"
                    as={'a'}
                    leftIcon={<BsPower />}
                  >
                    <Text mr={3}>Log Out</Text>
                  </Button>
                </MenuItem>

                <Center mt="40px">
                  <Button
                    size="sm"
                    onClick={() => window.open(LINK.googleReview, '_blank')}
                    variant="outline"
                  >
                    <Text>Leave a review</Text>
                    <Image
                      src={googleImage}
                      alt="Logo"
                      boxSize="30px"
                      objectFit="cover"
                    />
                  </Button>
                </Center>
                <Center mt="40px">
                  <Text fontSize="sm">
                    &copy; {1900 + new Date().getYear()}{' '}
                    <Link href="https://eeze.co" isExternal color="primary.800">
                      EEZE Inc.
                    </Link>
                  </Text>
                </Center>
                <Center>
                  <Text fontSize="sm">All Rights Reserved</Text>
                </Center>
                {isDev && (
                  <Center>
                    <Text fontSize="xs" color="gray" as="i">
                      DEV {deployed}
                    </Text>
                  </Center>
                )}
              </MenuList>
            </Menu>
          </Flex> */}
        </Flex>
      </Box>
    </>
  );
}

export default Header;
