import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  Image,
  Button,
  Center,
  Link,
  // useColorModeValue,
  Text,
  // Spacer,
  VStack,
  Drawer,
  DrawerContent,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import {
  FiHome,
  FiList,
  FiSearch,
  FiSettings,
  FiSend,
  FiMenu,
  FiPower,
} from 'react-icons/fi';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';

import logoImage from '../assets/logo.png';
import googleImage from '../assets/google_icon.png';
import { isDev, db, deployed, LINK, dealerIDVPortal } from '../constants';

// const LinkItemsLoggedOut = [
//   { name: 'Login', icon: FiList, path: '/login' },
//   { name: 'Sign Up', icon: FiHome, path: '/signup' },
// ];

const LinkItems = [
  { name: 'Home', icon: FiHome, path: '/' },
  { name: 'View Referrals', icon: FiList, path: '/referrals' },
  { name: 'Search Listings', icon: FiSearch, path: '/listings' },
  { name: 'Profile', icon: FiSettings, path: '/profile' },
];

const LinkItemsIDV = [
  { name: 'Home', icon: FiHome, path: '/' },
  { name: 'Verifications', icon: FiList, path: '/verifications' },
  { name: 'Requests', icon: FiSend, path: '/request-history' },
  { name: 'Profile', icon: FiSettings, path: '/profile' },
];

export default function AppBody(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userData, setUserData] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);
  const auth = getAuth();

  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.currentUser) return;

    const unsubscribe = onSnapshot(
      doc(db, 'partnerMembers', auth.currentUser.uid),
      doc => {
        setUserData(doc.data());
      }
    );
    return unsubscribe;
  }, [auth]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        const docRef = doc(db, 'partnerMembers', auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // console.log('user in onAuthStateChanged', user);
          // console.log('user found user', auth.currentUser);
          // console.log('data', docSnap.data());
          setUserData(docSnap.data());
          setLoggedIn(true);
        } else {
          // console.log("first login")
          setLoggedIn(true);
        }
        // setCurrentUser(user);
      } else {
        // console.log('there is no user');
        setUserData(null);
        setLoggedIn(false);
      }
      //   setIsLoading(false);
      setCheckingStatus(false);
    });
    return unsubscribe;
  }, [auth]);

  const onLogout = () => {
    if (!window.confirm('Logout as ' + userData.email + ', are you sure?')) {
      return;
    }
    // console.log('auth', auth);
    // auth.signOut();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        toast({ description: 'Logged Out' });
        navigate('/login');
        return;
      })
      .catch(error => {
        console.log('logout error');
        console.error(error);
        toast({ description: 'Log out error' });
        // An error happened.
      });
  };

  const SidebarContent = ({ onClose, ...rest }) => {
    return (
      <Box
        bg={'blackAlpha.100'}
        borderRight="1px"
        borderRightColor={'gray.300'}
        // w={{ base: 80, md: 60 }}
        w={{ base: 'full', md: 60 }}
        pos="fixed"
        h="full"
        {...rest}
      >
        <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
          <Image src={logoImage} alt="Logo" width="120px" objectFit="cover" />
          <CloseButton
            display={{ base: 'flex', md: 'none' }}
            onClick={onClose}
          />
        </Flex>

        {!dealerIDVPortal &&
          LinkItems.map(link => (
            <NavItem key={link.name} icon={link.icon} path={link.path}>
              {link.name}
            </NavItem>
          ))}

        {dealerIDVPortal &&
          LinkItemsIDV.map(link => (
            <NavItem key={link.name} icon={link.icon} path={link.path}>
              {link.name}
            </NavItem>
          ))}
        <VStack h={'60%'}>
          <Button mt={20} 
          fontWeight='normal'
          colorScheme='primary'
          variant="link" rightIcon={<ExternalLinkIcon />}
          onClick={() => window.open(LINK.warrantyInfoUrl, '_blank')}
          >
            Warranty Info
          </Button>
          <Box flexGrow={1} />

          <Button
            onClick={onLogout}
            // w="full"
            size="sm"
            variant="ghost"
            fontWeight="normal"
            leftIcon={<FiPower />}
            _hover={{
              bg: 'primary.500',
              color: 'white',
            }}
          >
            Log Out
          </Button>
          <br />
          <Button
            // w={50}
            // m='10px'
            size="sm"
            fontWeight="normal"
            onClick={() => window.open(LINK.googleReview, '_blank')}
            variant="ghost"
            _hover={{
              bg: 'primary.500',
              color: 'white',
            }}
          >
            <Box mr="10px">
              <Image
                src={googleImage}
                alt="Logo"
                boxSize="30px"
                objectFit="cover"
              />
            </Box>
            <Text>Leave a review</Text>
          </Button>
          <br />

          <Center>
            <Text fontSize="sm" textAlign="center">
              &copy; {1900 + new Date().getYear()}{' '}
              <Link href="https://eeze.co" isExternal color="primary.800">
                EEZE Inc.
              </Link>
            </Text>
          </Center>
          <Center>
            <Text fontSize="sm" textAlign="center">
              All Rights Reserved
            </Text>
          </Center>
          {isDev && (
            <Center>
              <Text fontSize="xs" color="gray" as="i" textAlign="center">
                DEV {deployed}
              </Text>
            </Center>
          )}
        </VStack>
      </Box>
    );
  };

  const NavItem = ({ icon, children, path, ...rest }) => {
    const location = useLocation();
    return (
      <Box
        // as={location.pathname === path ? '' : 'a'}
        // href={path}
        onClick={() => navigate(path)}
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}
      >
        <Flex
          align="center"
          p="4"
          role="group"
          cursor="pointer"
          borderWidth={location.pathname === path ? '1px' : '0'}
          borderColor="gray.300"
          borderLeftWidth={location.pathname === path ? '5px' : '0'}
          borderLeftColor="primary.500"
          bg={location.pathname === path ? 'white' : ''}
          mb="1"
          mx="2"
          borderRadius="md"
          // borderLeftRadius="lg"
          // ml="4"
          // borderRightWidth="0px"
          // mr={location.pathname === path ? '-1px' : '0px'}
          fontWeight={location.pathname === path ? 'bold' : ''}
          _hover={{
            bg: 'white',
            // bg: 'primary.500',
            // color: 'white',
          }}
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              // _groupHover={{
              //   color: 'white',
              // }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Box>
    );
  };

  const MobileNav = ({ onOpen, ...rest }) => {
    return (
      <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 24 }}
        height="20"
        alignItems="center"
        bg={'blackAlpha.100'}
        borderBottomWidth="1px"
        borderBottomColor={'gray.200'}
        justifyContent="space-between"
        {...rest}
      >
        <IconButton
          variant="outline"
          onClick={onOpen}
          aria-label="open menu"
          icon={<FiMenu />}
        />
        <Image src={logoImage} alt="Logo" width="120px" objectFit="cover" />
        <IconButton disabled />
      </Flex>
    );
  };

  return (
    <Box
      minH="100vh"
      // bg={useColorModeValue('gray.100', 'gray.900')}
    >
      <SidebarContent
        onClose={() => onClose}
        // display={{ base: 80, md: 'block' }}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4" zIndex={99999}>
        {/* Content */}
        {props.children}
      </Box>
    </Box>
  );
}

// export default function AppBody(props) {
//   return (
//     <Container
//     maxW='1200px'
//       px={2}
//       minH={'calc(100vh - 120px)'}
//     >

//     {/* <Box> */}
//       {props.children}
//     {/* </Box> */}
//       </Container>
//   );
// }

// export default function AppBody(props) {
//   return (
//     <Container
//       p={2}
//       minH={'calc(100vh - 120px)'}
//     >

//     {/* <Box> */}
//       {props.children}
//     {/* </Box> */}
//       </Container>
//   );
// }
