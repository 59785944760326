import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// import { useContext } from 'react';
import React from "react";
import {
  ChakraProvider,
  // theme,
} from '@chakra-ui/react';
// import Navbar from './components/Navbar'
import PrivateRoute from './components/PrivateRoute'
import PublicOnlyRoute from './components/PublicOnlyRoute'
import Listings from './pages/Listings'
import Homepage from './pages/Homepage'
import Profile from './pages/Profile'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import ContractSigned from './pages/ContractSigned'
import Contract from './pages/Contract'
import NotFound from './pages/NotFound'
import Referrals from './pages/Referrals'
import Requests from './pages/Requests'
import Verifications from './pages/Verifications'
import Warranty from './pages/Warranty'
import Users from './pages/Users'
import ForgotPassword from './pages/ForgotPassword'
import EditListing from './pages/EditListing'
import Listing from './pages/Listing'
import theme from './theme'

import { DataProvider } from './context/DataContext';

function App() {

  return (
    <>
      <ChakraProvider theme={theme}>
        <DataProvider>
          <Router>
            {/* <Header/> */}
            <Routes>
              {/* <Route path='/welcome' element={<PrivateRoute />}>
                <Route path='/welcome' element={<Welcome />} />
              </Route> */}
              <Route path='/profile' element={<PrivateRoute />}>
                <Route path='/profile' element={<Profile />} />
              </Route>
              {/* <Route path='/verify' element={<PrivateRoute />}>
                <Route path='/verify' element={<Verify />} />
              </Route> */}

              {/* <Route path='/verify-final' element={<PrivateRoute />}>
                <Route path='/verify-final' element={<VerifyFinal />} />
              </Route> */}

              {/* <Route path='/setup' element={<PrivateRoute />}>
                <Route path='/setup' element={<Setup />} />
              </Route> */}

              <Route path='/' element={<PrivateRoute />}>
                <Route path='/' element={<Homepage />} />
              </Route>

              <Route path='/login' element={<PublicOnlyRoute />}>
                <Route path='/login' element={<Login />} />
              </Route>

              <Route path='/signup' element={<PublicOnlyRoute />}>
                <Route path='/signup' element={<SignUp />} />
              </Route>

              <Route path='/start' element={<PublicOnlyRoute />}>
                <Route path='/start' element={<SignUp showPartners={true}/>} />
              </Route>

              <Route path='/edit/:listingUid' element={<PrivateRoute />}>
                <Route path='/edit/:listingUid' element={<EditListing />} />
              </Route>

              <Route path='/warranty/:listingUid' element={<PrivateRoute />}>
                <Route path='/warranty/:listingUid' element={<Warranty />} />
              </Route>
              
              <Route path='/listings' element={<PrivateRoute />}>
                <Route path='/listings' element={<Listings />} />
              </Route>

              <Route path='/users' element={<PrivateRoute />}>
                <Route path='/users' element={<Users />} />
              </Route>

              <Route path='*' element={<NotFound />} />

              <Route path='/contract-signed' element={<ContractSigned />} />

              <Route path='/forgot-password' element={<ForgotPassword />} />

              <Route path='/referrals' element={<PrivateRoute />}>
                <Route path='/referrals' element={<Referrals />} />
              </Route>

              <Route path='/verifications' element={<PrivateRoute />}>
                <Route path='/verifications' element={<Verifications />} />
              </Route>

              <Route path='/request-history' element={<PrivateRoute />}>
                <Route path='/request-history' element={<Requests />} />
              </Route>

              <Route path='/contract' element={<PrivateRoute />}>
                <Route path='/contract' element={<Contract />} />
              </Route>

              <Route path='/listing/:listingUid/:refSlug' element={<PrivateRoute />}>
                <Route path='/listing/:listingUid/:refSlug' element={<Listing />} />
              </Route>

              <Route path='/listing/:listingUid' element={<PrivateRoute />}>
                <Route path='/listing/:listingUid' element={<Listing />} />
              </Route>

              {/* <Route
                path='/listing/:listingUid/:refSlug'
                element={<Listing />}
              /> */}
              {/* <Route
                path='/listing/:listingUid'
                element={<Listing />}
              /> */}


            </Routes>
            {/* <Footer /> */}
          </Router>

          {/* <ToastContainer /> */}
        </DataProvider>
      </ChakraProvider>
    </>
  )
}

export default App
