import { useEffect, useState, useContext, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
import {
  collection,
  getDocs,
  doc,
  onSnapshot,
  // addDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import {
  Heading,
  Divider,
  Box,
  IconButton,
  // Accordion,
  // AccordionItem,
  // AccordionButton,
  // AccordionIcon,
  // AccordionPanel,
  Center,
  Card,
  Image,
  Button,
  List,
  ListItem,
  ListIcon,
  Avatar,
  Stack,
  HStack,
  Text,
  InputGroup,
  Input,
  InputRightElement,
  InputLeftElement,
  useToast,
  useDisclosure,
  Modal,
  ModalContent,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  ModalHeader,
} from '@chakra-ui/react';
import moment from 'moment';
import { SearchIcon, CloseIcon, CheckCircleIcon } from '@chakra-ui/icons';

import { db } from '../constants';
import DataContext from '../context/DataContext';
import AppBody from '../components/AppBody';

import SpinnerPage from '../components/SpinnerPage';

function Verifications() {
  const toast = useToast();
  const auth = getAuth();
  // const navigate = useNavigate();
  const inputRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [partnerData, setPartnerData] = useState();
  // const [listings, setListings] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [allData, setAllData] = useState(null);
  const [allFilteredData, setAllFilteredData] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { userData } = useContext(DataContext);
  const { partnerUid } = userData;

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'partners', partnerUid), doc => {
      const partner = doc.data();
      setPartnerData(partner);
    });
    return unsubscribe;
  }, [partnerUid]);

  const sortArray = array => {
    // descending order of postedAt or createdAt
    const sortedArray = array.sort((a, b) => {
      return (
        new Date(b.lastLogin || b.postedAt || b.createdAt) -
        new Date(a.lastLogin || a.postedAt || a.createdAt)
      );
    });
    return sortedArray;
  };

  useEffect(() => {
    const fetchListings = async () => {
      // get all listings and users for the refSlug
      try {
        // const myListings = [];
        const myUsers = [];

        if (partnerData) {
          const { refSlug } = partnerData;
          // get all listings, check for refSlug
          // const colRef = collection(db, 'listings');
          // const docsSnap = await getDocs(colRef);
          // if (docsSnap.docs.length > 0) {
          //   docsSnap.forEach(doc => {
          //     // if this partner slug is in listing.affiliateRef influencerRef or garageRef, add to array
          //     const listing = doc.data();

          //     if (listing?.affiliateRef === refSlug) {
          //       myListings.push(listing);
          //     }

          //     if (listing?.garageRef === refSlug) {
          //       myListings.push(listing);
          //     }

          //     if (listing?.influencerRef === refSlug) {
          //       myListings.push(listing);
          //     }
          //   });
          // }
          // get all users, check for refSlug
          const colRef2 = collection(db, 'users');
          const docsSnap2 = await getDocs(colRef2);
          if (docsSnap2.docs.length > 0) {
            docsSnap2.forEach(doc => {
              // if this partner uid is in listing.affiliateRef or listing.garageRef, add to array
              const user = doc.data();

              let licenseUrl = '';
              if (user?.licenseSelfieData?.licensePhotoFront) {
                licenseUrl = user?.licenseSelfieData?.licensePhotoFront;
              }

              if (user?.licenseSelfieData?.documentimage) {
                licenseUrl = user?.licenseSelfieData?.documentimage[0]?.url;
              }
              if (user?.affiliateRef === refSlug) {
                myUsers.push({
                  firstName: user.firstName,
                  lastName: user.lastName,
                  stageTwoVerified: user.stageTwoVerified,
                  stageOneVerified: user.stageOneVerified,
                  createdAt: user.createdAt,
                  address: user.address,
                  email: user.email,
                  cell: user.cell,
                  photoURL: user.photoURL,
                  uid: user.uid,
                  licenseUrl,
                });
              }

              if (user?.garageRef === refSlug) {
                myUsers.push({
                  firstName: user.firstName,
                  lastName: user.lastName,
                  stageTwoVerified: user.stageTwoVerified,
                  stageOneVerified: user.stageOneVerified,
                  createdAt: user.createdAt,
                  address: user.address,
                  email: user.email,
                  cell: user.cell,
                  photoURL: user.photoURL,
                  uid: user.uid,
                  licenseUrl,
                });
              }

              if (user?.influencerRef === refSlug) {
                myUsers.push({
                  firstName: user.firstName,
                  lastName: user.lastName,
                  stageTwoVerified: user.stageTwoVerified,
                  stageOneVerified: user.stageOneVerified,
                  createdAt: user.createdAt,
                  address: user.address,
                  email: user.email,
                  cell: user.cell,
                  photoURL: user.photoURL,
                  uid: user.uid,
                  licenseUrl,
                });
              }
            });
          }


          const finalMergedArray = [
            ...myUsers,
          ];

          const finalSortedMergedArray = sortArray(finalMergedArray);
          setAllData(finalSortedMergedArray);
          setAllFilteredData(finalSortedMergedArray);
          // console.log(finalSortedMergedArray);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        toast({
          description: 'Listing fetch error',
          status: 'error',
        });
      }
    };

    fetchListings();
  }, [toast, auth, partnerData]);

  const filterArray = (array, filterValue) => {
    const filteredArray = array.filter(
      item =>
        (item?.firstName &&
          item?.firstName.toLowerCase().includes(filterValue.toLowerCase())) ||
        (item?.lastName &&
          item?.lastName.toLowerCase().includes(filterValue.toLowerCase())) ||
        (item.eezeID &&
          item?.eezeID.toLowerCase().includes(filterValue.toLowerCase()))
    );
    return sortArray(filteredArray);
  };

  const handleFilter = e => {
    let inputValue = '';
    if (e?.target) {
      inputValue = e.target.value;
    }
    const filteredData = filterArray(allData, inputValue);
    // console.log('vehicleListings', vehicleListings);
    // console.log('filteredArray', filteredVehicles);
    setAllFilteredData(filteredData);
  };

  const handleClear = () => {
    inputRef.current.value = '';
    handleFilter();
    // inputRef.current.click();
    // inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
  };

  const renderUserModal = () => {
    if (!selectedUser) return null

    const user = selectedUser;

    return (
<Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
<ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="2xl">
          {user.firstName} {user.lastName}
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <HStack>
            <Avatar
              size="xl"
              src={user.photoURL}
              borderWidth="2px"
              borderColor={'#5e65a5'}
            />
            <Box>
              <Text>{user?.cell}</Text>
              <Text>{user?.email}</Text>
              <Text>
                {user?.address?.civic} {user?.address?.street}
              </Text>
              <Text>
                {user?.address?.city} {user?.address?.province}
              </Text>
              <Text>
                {user?.address?.country} {user?.address?.postal}
              </Text>
            </Box>
          </HStack>
          <Image
            size="xl"
            borderWidth="1px"
            borderColor="black"
            fallbackSrc="https://placehold.co/300x100?text=PIC"
            src={user?.licenseUrl}
          />

          <Box>
            <Heading size="md" mb={2}>
              Verified Information
            </Heading>
            <List spacing={2}>
              {[
                'Cell: passcode verified',
                'Email: passcode verified',
                'Identity vs photo ID',
                'Liveness detection',
                'Equifax Credit bureau authentication',
                "Driver's license",
                "Device location and carrier",
              ].map(item => (
                <ListItem key={item} fontSize="sm">
                  <ListIcon as={CheckCircleIcon} color="primary.500" />
                  {item}
                </ListItem>
              ))}
            </List>
            <Text fontSize="sm" mt={4}>
              Date Verified:{' '}
              {moment(user.createdAt, 'ddd MMM DD YYYY kk:mm:ss Z ZZ').format(
                'MMM D, YYYY - h:mma'
              )}
            </Text>
          </Box>
        </ModalBody>

        <ModalFooter>
          <HStack w="full" justify="space-between">
            <Button colorScheme="primary">Print PDF</Button>
            <Button
              // w="full"
              // variant="link"
              // fontWeight="normal"
              // size="sm"
              // colorScheme="primary"
              onClick={onClose}
            >
              CLOSE
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
      </Modal>
    );
  };
  const renderUser = user => {
    return (
      <>
        
          {/* {renderUserModal(user)} */}
      

        <Card
          key={user.uid}
          borderWidth={1}
          borderColor="darkgrey"
          // borderBottomWidth={1}
          // borderColor="black"
          // bg='red'
          // borderColor="primary.500"
          p={4}
          // pb={2}
        >
          <HStack
          // justify="space-between"
          // textAlign="center"
          >
            <Box w="100px">
              <Text as="i" color="grey">
                {moment(user.createdAt, 'ddd MMM DD YYYY kk:mm:ss Z ZZ').format(
                  'MM/DD/YYYY'
                )}
              </Text>
            </Box>

            <Box w="200px">
              <Text as="b">
                {/* <b> */}
                {user.firstName} {user.lastName}
                {/* </b> */}
                {/* {!user?.stageTwoVerified && ' (Unverified)'} */}
              </Text>
            </Box>

            {user.stageOneVerified ? (
              <Text w="200px">VERIFIED</Text>
            ) : (
              <Text as="i" w="200px" color="red.200">
                Incomplete
              </Text>
            )}
            <Text
              w="200px"
              // color="grey"
            >
              {user?.address?.city && user?.address?.city}
            </Text>
            <Text
              w="200px"
              // color="grey"
            >
              {user?.cell}
            </Text>

            <Button
              disabled={!user.stageOneVerified}
              size="sm"
              onClick={() => {
                setSelectedUser(user);
                onOpen()}}
              colorScheme={user.stageOneVerified ? 'primary' : 'secondary'}
            >
              Details
            </Button>
          </HStack>
        </Card>
      </>
    );
  };

  const renderAllData = () => {
    if (loading) {
      return <SpinnerPage />;
    }
    if (!allFilteredData) {
      return <SpinnerPage />;
    }

    return (
      <>
        {allFilteredData.length > 0 ? (
          <>
            {allFilteredData.map(item => {
              return renderUser(item);
            })}
            <br />
            <br />
          </>
        ) : (
          <Center mt={20}>
            <Text>No Results</Text>
          </Center>
        )}
      </>
    );
  };
  return (
    <>
    {renderUserModal()}
      <AppBody>
        <Heading size="lg">IDV Verifications</Heading>
        <Divider borderColor="primary.500" borderWidth={1} />

        <Text>
          Customers verified through your link{' '}
          {partnerData?.refSlug && 'eeze.co/' + partnerData?.refSlug}
        </Text>
        {/* <Text>
          Any EEZE customer or vehicle added using your referral link{' '}
          {partnerData?.refSlug && 'eeze.co/' + partnerData?.refSlug}
        </Text> */}
        <InputGroup my={3}>
          <InputLeftElement children={<SearchIcon color="grey" />} />
          <Input
            onChange={handleFilter}
            ref={inputRef}
            placeholder="Filter by Name"
            _placeholder={{ fontSize: 'sm', color: 'gray.500' }}
          />
          <InputRightElement>
            <IconButton
              p={5}
              variant="ghost"
              onClick={handleClear}
              size="xs"
              color="grey"
              icon={<CloseIcon />}
            />
          </InputRightElement>
        </InputGroup>
        {/* <Text as="i" align="center" color="grey">
          Search by Name, Vehicle, VIN or EEZE ID.{' '}
        </Text> */}
        {/* <Text as="i" align="center" color="lightgrey">
          Default sorted by last login or date created
        </Text> */}
        {/* <Divider borderColor="primary.500" borderWidth={1} my={2} /> */}
        <Stack
          // mt={2}
          spacing={1}
        >
          {renderAllData()}
        </Stack>
      </AppBody>
    </>
  );
}

export default Verifications;
