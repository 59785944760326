import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc, collection, getDocs } from 'firebase/firestore';
// import ReCAPTCHA from 'react-google-recaptcha';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import InputMask from 'react-input-mask';

// Chakra imports
import {
  Box,
  Flex,
  Fade,
  Button,
  Divider,
  IconButton,
  Image,
  FormControl,
  Heading,
  HStack,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Center,
  Input,
  Text,
  Select,
  // useDisclosure,
  // AspectRatio,
  // Modal,
  // ModalOverlay,
  // ModalContent,
  // ModalFooter,
  // ModalCloseButton,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
// import { FaRegPlayCircle } from 'react-icons/fa';
import { useToast } from '@chakra-ui/react';

import getIPDetails from '../services/getIPDetails';
import { db, deployed, dealerIDVPortal } from '../constants';
// import OAuth from '../components/OAuth';
import logoImage from '../assets/shield256.png';

// Assets
import signUpImage from '../assets/signUpImage.png';
import signUpImageIDV from '../assets/dealership-image.jpg';

function SignUp(props) {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const toast = useToast();
  const [refPartner, setRefPartner] = useState();
  const [isOpen, setIsOpen] = useState(false);

  // const {
  //   isOpen: isOpenVideo,
  //   onOpen: onOpenVideo,
  //   onClose: onCloseVideo,
  // } = useDisclosure();
  const [skipAutocomplete, setSkipAutocomplete] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [captchaIsDone, setcaptchaIsDone] = useState(false);
  const [formData, setFormData] = useState({
    primaryFirstName: '',
    primaryLastName: '',
    // primaryCell: '',
    primaryEmail: '',
    primaryTitle: '',
    type: '',
    password: '',
    address: '',
    businessName: '',
    businessPhone: '',
  });
  const {
    primaryEmail,
    password,
    primaryFirstName,
    primaryLastName,
    // primaryCell,
    // primaryTitle,
    type,
    address,
    businessPhone,
    businessName,
    // businessLegalName,
  } = formData;

  useEffect(() => {
    const fetchRef = async () => {
      let partnerSlug;

      if (queryParameters.get('ref')) {
        partnerSlug = queryParameters.get('ref');
        // ex /?ref=garage1
      }
      if (queryParameters.get('aref')) {
        partnerSlug = queryParameters.get('aref');
        // ex /?aref=affiliate1
      }
      if (queryParameters.get('a')) {
        partnerSlug = queryParameters.get('a');
        // ex /?a=affiliate1
      }
      if (queryParameters.get('iref')) {
        partnerSlug = queryParameters.get('iref');
        // ex /?iref=influencer1
      }
      if (queryParameters.get('i')) {
        partnerSlug = queryParameters.get('i');
        // ex /?i=influencer1
      }

      if (!partnerSlug) {
        return;
      }
      // console.log('partnerSlug', partnerSlug);
      //  find the partner for this slug

      // get partner with that refSlug
      const colRef3 = collection(db, 'partners');
      const docsSnap3 = await getDocs(colRef3);
      if (docsSnap3.docs.length > 0) {
        docsSnap3.forEach(doc => {
          // if this partner uid is in listing.affiliateRef or listing.garageRef, add to array
          const partner = doc.data();

          if (partner?.refSlug === partnerSlug) {
            if (partner?.type !== 'influencer') {
              setRefPartner({
                businessName: partner.businessName,
                photoURL: partner.photoURL,
              });
              setIsOpen(true);
            }
          }
        });
      }

      try {
      } catch (error) {
        console.log(error);
      }
    };

    if (props.showPartners) {
      fetchRef();
    }
  }, [queryParameters, props]);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  // const onChangeCaptcha = value => {
  //   // console.log('Captcha value:', value);
  //   setcaptchaIsDone(true);
  // };

  const onChange = e => {
    // do not allow more than 10 cell phone digits or letters
    // if (e.target.id === 'cell' && e.target.value.length > 10) return;
    // if (e.target.id === 'cell' && isNaN(e.target.value)) return;
    // if (e.target.id === 'businessPhone' && e.target.value.length > 10) return;
    // if (e.target.id === 'businessPhone' && isNaN(e.target.value)) return;
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onChangeAddressComponent = e => {
    setFormData(prevState => ({
      ...prevState,
      address: {
        ...formData.address,
        [e.target.id]: e.target.value,
      },
    }));
  };

  const onSubmit = async e => {
    e.preventDefault();

    try {
      const auth = getAuth();

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        primaryEmail,
        password
      ).catch(e => {
        // console.log(e)
        if (e.code === 'auth/email-already-in-use') {
          toast({
            title: 'Email already in use',
            status: 'error',
            isClosable: true,
          });
          return;
        }
      });

      const user = userCredential.user;

      // create the first partnerMember and the partner parent with the same uid

      const ipLocation = await getIPDetails();

      const partnerDataToSave = { ...formData };
      delete partnerDataToSave.password;

      let slug =
        partnerDataToSave.businessName.split(' ')[0] ||
        partnerDataToSave.businessName;
      if (slug.toLowerCase() === 'the') {
        slug =
          partnerDataToSave.businessName.split(' ')[1] ||
          partnerDataToSave.businessName;
      }
      // remove any special chars
      const refSlug = slug.replace(/[^a-zA-Z0-9 ]/g, '');

      partnerDataToSave.createdAt = new Date().toString();
      partnerDataToSave.lastLogin = new Date().toString();
      partnerDataToSave.refSlug = refSlug.toLowerCase();
      partnerDataToSave.signupIP = {
        ...ipLocation,
        date: new Date().toString(),
      };
      partnerDataToSave.interacEmail = primaryEmail;
      partnerDataToSave.approved = false;
      partnerDataToSave.uid = user.uid;
      partnerDataToSave.notes = '';

      // standard commission
      partnerDataToSave.fees = {
        customerSignup: 25,
        uploadInspection: 35,
        warrantyPowertrain1Year: 50,
        warrantyComprehensive1Year: 100,
        warrantyComprehensive2Year: 150,
        warrantyComprehensive3Year: 200,
      };

      const memberDataToSave = {};
      memberDataToSave.createdAt = new Date().toString();
      memberDataToSave.lastLogin = new Date().toString();
      memberDataToSave.firstName = formData.primaryFirstName;
      memberDataToSave.lastName = formData.primaryLastName;
      memberDataToSave.businessName = formData.businessName;
      memberDataToSave.title = formData.primaryTitle;
      memberDataToSave.email = formData.primaryEmail;
      memberDataToSave.phone = formData.businessPhone;
      memberDataToSave.cell = '';
      memberDataToSave.admin = true;
      memberDataToSave.active = true;
      memberDataToSave.partnerUid = user.uid;
      memberDataToSave.memberUid = user.uid;

      await setDoc(doc(db, 'partners', user.uid), partnerDataToSave);
      await setDoc(doc(db, 'partnerMembers', user.uid), memberDataToSave);

      let ref;
      if (queryParameters.get('ref')) {
        ref = queryParameters.get('ref');
        // ex signup?ref=garage1
      }
      let aref;
      if (queryParameters.get('aref')) {
        aref = queryParameters.get('aref');
        // ex signup?aref=affiliate1
      }
      if (queryParameters.get('a')) {
        aref = queryParameters.get('a');
        // ex signup?a=affiliate1
      }
      let iref;
      if (queryParameters.get('iref')) {
        iref = queryParameters.get('iref');
        // ex signup?iref=influencer1
      }
      if (queryParameters.get('i')) {
        iref = queryParameters.get('i');
        // ex signup?i=influencer1
      }

      const refRef = ref ? '&ref=' + ref : '';
      const arefRef = aref ? '&aref=' + aref : '';
      const irefRef = iref ? '&iref=' + iref : '';
      const toNav = '/?' + refRef + arefRef + irefRef;
      navigate(toNav);
    } catch (error) {
      setIsSubmitting(false);
      toast({
        title: 'Signup error. Please try again',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const clear = () => {
    setSkipAutocomplete(false);
    setFormData(prevState => ({
      ...prevState,
      businessName: '',
      businessLegalName: '',
      address: {
        apt: '',
        civic: '',
        street: '',
        city: '',
        province: '',
        country: '',
        postal: '',
      },
    }));
  };

  const onChangeAddress = async e => {
    // get adddress from autocomplete but it does not include postal code or latlng

    // console.log('e.value', e.value);
    // object like this from autocomplete
    // description: "2121 Lake Shore Boulevard West, Etobicoke, ON, Canada"
    // matched_substrings: [{…}]
    // place_id: "ChIJ46xG8Qw2K4gR134X1tX74kc"
    // reference: "ChIJ46xG8Qw2K4gR134X1tX74kc"
    // structured_formatting: {main_text: '2121 Lake Shore Boulevard West', main_text_matched_substrings: Array(1), secondary_text: 'Etobicoke, ON, Canada'}
    // terms: (5) [{…}, {…}, {…}, {…}, {…}]
    // 0: {offset: 0, value: '2121'}
    // 1: {offset: 5, value: 'Lake Shore Boulevard West'}
    // 2: {offset: 32, value: 'Etobicoke'}
    // 3: {offset: 43, value: 'ON'}
    // 4: {offset: 47, value: 'Canada'}
    // types: (2) ['street_address', 'geocode']

    // const response = await fetch(
    //   `https://maps.googleapis.com/maps/api/place/details/output?place_id=${e.value.place_id}&key=AIzaSyDlpfkT6r8HIdjtfIl9vxdZSJCY0tDmzu8`
    // ).catch(e => {
    //   console.log(e);
    // });
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(e.value.description)}&key=AIzaSyDlpfkT6r8HIdjtfIl9vxdZSJCY0tDmzu8`
    ).catch(e => {
      console.log(e);
    });

    const data = await response?.json();
    if (data?.status === 'OK') {
      // console.log('data', data);
      // console.log('OK data.results[0]', data.results[0]);

      const fullAddObj = data.results[0];
      const arr1 = fullAddObj.address_components;
      const addressComponents = {};
      arr1.forEach((item, i) => {
        addressComponents[item.types[0]] = item.long_name;
      });

      const title = e.value.structured_formatting.main_text;
      setFormData(prevState => ({
        ...prevState,
        // warningsStage1: newWarningsStage1,
        businessName: title,
        businessLegalName: title,
        address: {
          ...fullAddObj,
          types: e.value.types,
          // ...addressComponents,
          apt: '',
          civic: addressComponents?.street_number || '',
          street: addressComponents?.route || '',
          city:
            addressComponents?.locality ||
            addressComponents?.administrative_area_level_2 ||
            '',
          province: addressComponents?.administrative_area_level_1 || '',
          country: addressComponents.country,
          postal: addressComponents?.postal_code || '',
          // currentIP: ip,
          // homeDistanceFromIP: homeDistanceFromIP,
        },
      }));
    } else {
      // error with geocode address, use autocomplete
      const fullObj = e.value.terms;
      const addressParts = {
        apt: '',
        civic: fullObj[0]?.value || '',
        street: fullObj[1]?.value || '',
        city: fullObj[2]?.value || '',
        province: fullObj[3]?.value || '',
        country: fullObj[4]?.value || '',
        postal: fullObj[5]?.value || '',
      };

      setFormData(prevState => ({
        ...prevState,
        address: {
          ...addressParts,
          ...e.value,
        },
      }));
    }
  };

  // console.log('formData', formData);

  let viewAutoComplete = true;
  if (businessName) {
    viewAutoComplete = false;
  }
  if (address.civic) {
    viewAutoComplete = false;
  }
  if (skipAutocomplete) {
    viewAutoComplete = false;
  }

  return (
    <Flex position="relative" mb="40px">
      {/* <Modal isOpen={isOpenVideo} onClose={onCloseVideo} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <AspectRatio ratio={16 / 9}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/WLES7VglsbE?si=kalZLwTGbBrJeTeT&autoplay=1"
              title="Partner Signup"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            />
          </AspectRatio>

          <ModalFooter>
            <Button
              w="full"
              variant="link"
              colorScheme="primary"
              onClick={onCloseVideo}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}

      {/* <Flex
        h={{ sm: 'initial', md: '105vh', lg: '105vh' }}
        w="100%"
        maxW="1044px"
        mx="auto"
        // mx={5}
        // mx={{sm: 0, md: 0, lg: 0}}
        // justify='center'
        // align='center'
        justifyContent="space-between"
        // justifyContent="center"
        mb={'35px'}
        // mb={{sm: "15px", md: "55px", lg: "135px"}}
        pt={{ sm: '50px', md: '0px' }}
      > */}
      <Flex
        alignItems="center"
        // w='100%'
        justifyContent="start"
        style={{ userSelect: 'none' }}
        // display={{ base: '100%', md: '50%', lg: '50%' }}
        w={{ base: '100%', md: '50%', lg: '50%' }}
        // w={{ base: '100%', md: '50%', lg: '42%' }}
      >
        <Flex
          direction="column"
          w="100%"
          background="transparent"
          p="28px"
          // mt={2}
          // mt={{ md: '100px', lg: '80px' }}
        >
          {refPartner?.businessName && (
            <Fade in={isOpen}>
              <Center>
                <Heading fontSize="sm">{refPartner.businessName}</Heading>
              </Center>
              <Center>
                {refPartner?.photoURL && (
                  <>
                    <Box>
                      <Image
                        src={refPartner.photoURL}
                        h="60px"
                        maxW="200px"
                        objectFit="contain"
                        alt="Company Logo"
                      />
                    </Box>
                  </>
                )}
              </Center>
              <Center mt={2}>
                <Text>presents</Text>
              </Center>
              <Divider borderColor="primary.800" my={2} />
            </Fade>
          )}
          <Center>
            <Image
              src={logoImage}
              alt="Logo"
              boxSize="60px"
              objectFit="cover"
            />
          </Center>

          <Center>
            <Heading mb={1} textAlign="center">
              Sign up to the
              <Text
                  fontWeight="bold"
                  bgGradient="linear(to-l, primary.800,primary.300)"
                  bgClip="text"
                >
                  {dealerIDVPortal ? 'EEZE Identity Verification Portal' : 'EEZE Partner Portal'}
                  
                </Text>
            </Heading>
          </Center>
          {/* <Center mb={1}>
            <Button
              colorScheme="primary"
              variant="outline"
              size="sm"
              onClick={onOpenVideo}
              mt={2}
              leftIcon={<FaRegPlayCircle size="25px" p={2} />}
            >
              Watch Signup Video
            </Button>
          </Center> */}
          <Divider borderColor="primary.800" />
          {/* <OAuth /> */}

          {!businessName ? (
            <Center textAlign="center">
              <Text my={2}>Tell us about your business</Text>
            </Center>
          ) : (
            <Text mt={4} as="b" fontSize="sm">
              Business Name
              <Text color='red' as='span'>
            {!businessName && ' *'}
            </Text>
            </Text>
          )}

          {viewAutoComplete && (
            <>
              {/* <Center textAlign="center">
                <Text>You can pre-fill the form with data from Google</Text>
              </Center> */}
              <GooglePlacesAutocomplete
                apiKey="AIzaSyDlpfkT6r8HIdjtfIl9vxdZSJCY0tDmzu8"
                selectProps={{
                  placeholder: 'Search your business name here',
                  styles: {
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      // borderColor: isEditing ? 'blue' : 'inherit',
                      // borderColor: state.isFocused ? 'grey' : 'blue',
                    }),
                  },
                  address,
                  onChange: onChangeAddress,
                }}
                autocompletionRequest={{
                  types: ['establishment'],
                  componentRestrictions: {
                    country: ['ca'],
                    // country: countryRestriction
                    //   ? countryRestriction
                    //   : ['us'],
                  },
                }}
              />
            </>
          )}

          {!viewAutoComplete ? (
            <>
              {/* <Text color="grey">Business Name</Text> */}
              <Input
                borderRadius="10px"
                // mt="14px"
                fontSize="sm"
                placeholder="Business Name"
                size="lg"
                borderColor={!businessName ? 'red' : 'inherit'}
                // fontWeight='bold'
                id="businessName"
                value={businessName}
                onChange={onChange}
              />
              {/* <Text mt="4px" color="grey">
                  Legal Name
                </Text>
                <Input
                  borderRadius="10px"
                  fontSize="sm"
                  placeholder="Legal Name"
                  size="lg"
                  id="businessLegalName"
                  value={businessLegalName}
                  onChange={onChange}
                /> */}
              <Text
                // color="grey"
                mt={2}
                as="b"
                fontSize="sm"
              >
                Address
                <Text color='red' as='span'>
            {(!address.civic ||
            !address.street ||
            !address.city ||
            !address.province ||
            !address.postal)
            && ' *'}
            </Text>
              </Text>
              <HStack>
                <Input
                  borderRadius="10px"
                  // mb="14px"
                  fontSize="sm"
                  placeholder="Civic"
                  // w="40%"
                  borderColor={!address.civic ? 'red' : 'inherit'}
                  size="lg"
                  id="civic"
                  value={address?.civic || ''}
                  onChange={onChangeAddressComponent}
                />
                <Input
                  borderRadius="10px"
                  // mb="14px"
                  fontSize="sm"
                  placeholder="Suite #"
                  // w="40%"
                  size="lg"
                  id="apt"
                  value={address?.apt || ''}
                  onChange={onChangeAddressComponent}
                />
              </HStack>
              <HStack mt={2}>
                <Input
                  borderRadius="10px"
                  // mb="14px"
                  fontSize="sm"
                  placeholder="Street"
                  borderColor={!address.street ? 'red' : 'inherit'}
                  size="lg"
                  id="street"
                  value={address?.street || ''}
                  // value={primaryLastName}
                  onChange={onChangeAddressComponent}
                />
                <Input
                  borderRadius="10px"
                  // mb="14px"
                  fontSize="sm"
                  placeholder="City"
                  borderColor={!address.city ? 'red' : 'inherit'}
                  // w='30%'
                  size="lg"
                  id="city"
                  value={address?.city || ''}
                  onChange={onChangeAddressComponent}
                />
              </HStack>
              <HStack mt={2}>
                {/* <Input
                    borderRadius="10px"
                    // mb="14px"
                    fontSize="sm"
                    placeholder="Country"
                    // w='30%'
                    size="lg"
                    id="country"
                    value={address?.country || ''}
                    onChange={onChangeAddressComponent}
                  /> */}
                <Input
                  borderRadius="10px"
                  // mb="14px"
                  fontSize="sm"
                  placeholder="Province"
                  borderColor={!address.province ? 'red' : 'inherit'}
                  size="lg"
                  id="province"
                  value={address?.province || ''}
                  onChange={onChangeAddressComponent}
                />
                <Input
                  borderRadius="10px"
                  // mb="14px"
                  fontSize="sm"
                  placeholder="Postal Code"
                  borderColor={!address.postal ? 'red' : 'inherit'}
                  size="lg"
                  id="postal"
                  value={address?.postal || ''}
                  onChange={onChangeAddressComponent}
                />
              </HStack>

              <Button
                size="sm"
                variant="link"
                onClick={clear}
                fontWeight='light'
                // colorScheme="primary"
                mt={4}
              >
                Clear and reselect
              </Button>
            </>
          ) : (
            <Box>
              <br />
              <Center>
                <Button
                  size="sm"
                  variant="link"
                  onClick={() => setSkipAutocomplete(true)}
                  colorScheme="primary"
                >
                  Click here if not in the list
                </Button>
              </Center>
            </Box>
          )}

{!dealerIDVPortal ? (
<>
  <Text
  // color="grey"
  mt={4}
  as="b"
  fontSize="sm"
  >
            Partner Type
            <Text color='red' as='span'>
            {!type && ' *'}
            </Text>
          </Text>
          <Select w="100%" id="type" value={type} onChange={onChange}>
            <option value="" color="red">
              Select
            </option>
            <option value="garage">
              Service Center (garage or repair center)
            </option>
            <option value="affiliate">
              Marketing Affiliate (company or association)
            </option>
            <option value="influencer">
              Individual (influencer or EEZE fan)
            </option>
          </Select>
    </>

          ) : (
<>
  <Text
  // color="grey"
  mt={4}
  as="b"
  fontSize="sm"
  >
            Business Type
            <Text color='red' as='span'>
            {!type && ' *'}
            </Text>
          </Text>
          <Select w="100%" id="type" value={type} onChange={onChange}>
            <option value="" color="red">
              Select
            </option>
            <option value="garage">
              Dealership
            </option>
            <option value="garage">
              Real Estate Agency
            </option>
            <option value="garage">
              Car Rental
            </option>
          </Select>
    </>


          )}
          <Text
            // color="grey"
            mt={4}
            as="b"
            fontSize="sm"
          >
            Phone
            <Text color='red' as='span'>
            {!businessPhone && ' *'}
            </Text>
          </Text>
          <InputGroup
            // mb="14px"
            // mt="14px"
            // mt={4}
            borderRadius="10px"
          >
            <InputLeftAddon children="+1" />
            <Input
              fontSize="sm"
              as={InputMask}
              mask="(999) 999-9999"
              maskChar={null}
              type="tel"
              placeholder="(   )"
              // size="lg"
              id="businessPhone"
              value={businessPhone}
              onChange={onChange}
            />
          </InputGroup>

          {/* <Center>
              <Text
                // mb="16px"
                my={2}
              >
                Primary Contact Information
              </Text>
            </Center> */}
          <Text
            // color="grey"
            mt={2}
            as="b"
          >
            Main Contact
            <Text color='red' as='span'>
            {!primaryFirstName && ' *'}
            </Text>
          </Text>
          <form onSubmit={onSubmit}>
            <FormControl>
              <HStack>
                <Input
                  borderRadius="10px"
                  // mb="14px"
                  fontSize="sm"
                  placeholder="First Name"
                  size="lg"
                  id="primaryFirstName"
                  value={primaryFirstName}
                  onChange={onChange}
                />
                <Input
                  borderRadius="10px"
                  // mb="14px"
                  fontSize="sm"
                  placeholder="Last Name"
                  size="lg"
                  id="primaryLastName"
                  value={primaryLastName}
                  onChange={onChange}
                />
              </HStack>
              {/* <Input
                  borderRadius="10px"
                  mt="14px"
                  fontSize="sm"
                  placeholder="Title"
                  size="lg"
                  id="primaryTitle"
                  value={primaryTitle}
                  onChange={onChange}
                /> */}

              <Input
                borderRadius="10px"
                mb="14px"
                mt="14px"
                fontSize="sm"
                type="email"
                placeholder="Email"
                size="lg"
                id="primaryEmail"
                value={primaryEmail}
                onChange={onChange}
              />
              <InputGroup>
                <Input
                  borderRadius="10px"
                  // mb="14px"
                  fontSize="sm"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  size="lg"
                  id="password"
                  value={password}
                  onChange={onChange}
                />
                <InputRightElement h={'full'}>
                  <IconButton
                    size={'lg'}
                    // mb="14px"
                    variant="ghost"
                    icon={
                      showPassword ? (
                        <ViewOffIcon color={'secondary.300'} boxSize={6} />
                      ) : (
                        <ViewIcon color={'secondary.300'} boxSize={6} />
                      )
                    }
                    aria-label={'Toggle'}
                    onClick={togglePasswordVisibility}
                  />
                </InputRightElement>
              </InputGroup>
              {password && password.length <= 6 && (
                <Center>
                  <Text as="i" fontSize="sm" color="grey" align="center">
                    Must be at least 7 characters
                  </Text>
                </Center>
              )}
              {/* <Center>
                  <ReCAPTCHA
                    sitekey={recaptchaKey}
                    onChange={onChangeCaptcha}
                  />
                </Center> */}
              {
                // captchaIsDone &&
                primaryFirstName &&
                primaryLastName &&
                primaryEmail &&
                type &&
                // primaryTitle &&
                businessPhone &&
                businessName &&
                address &&
                address.civic &&
                address.street &&
                address.city &&
                address.province &&
                address.postal &&
                password &&
                password.length > 6 ? (
                  <Button
                    type="submit"
                    colorScheme="primary"
                    w="100%"
                    mb="5px"
                    // mt={2}
                    mt="24px"
                    isLoading={isSubmitting ? true : false}
                    disabled={isSubmitting ? true : false}
                  >
                    Sign Up
                  </Button>
                ) : (
                  <Button
                    // type="submit"
                    colorScheme="primary"
                    w="100%"
                    mb="5px"
                    // mt={2}
                    mt="24px"
                    disabled
                  >
                    Sign Up
                  </Button>
                )
              }
            </FormControl>
          </form>

          <Center>
            <Text fontSize="sm">By creating an account you agree to</Text>
          </Center>
          <Center mb="5">
            <Text fontSize="sm">
              our{' '}
              <a
                href="https://eeze.co/terms-of-use/"
                target="_blank"
                rel="noreferrer"
              >
                terms
              </a>{' '}
              and{' '}
              <a
                href="https://eeze.co/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                privacy policy
              </a>
            </Text>
          </Center>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            mt="0px"
          >
            <Text fontWeight="medium">
              {` Already have an account? `}
              <Link as="span" ms="5px" to="/login">
                Login
              </Link>
            </Text>
            {/* </Flex> */}
          </Flex>
        </Flex>

        <Box
          display={{ base: 'none', md: 'block' }}
          overflowX="hidden"
          h="100%"
          w="50vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={dealerIDVPortal ? signUpImageIDV : signUpImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
      <Text fontSize="sm" color="grey" as="i" position="absolute" bottom={0}>
        {deployed}
      </Text>
    </Flex>
  );
}

export default SignUp;
