import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Text,
} from '@chakra-ui/react';
import {
  BsChevronLeft,
} from 'react-icons/bs';

function BackButton(props) {
  const navigate = useNavigate();
  return (
    <>
      <Box
        // px={2}
        h={'60px'}
      >
            <Button
              leftIcon={<BsChevronLeft />}
              pl={1}
              onClick={() => navigate(-1)}
              variant="ghost"
            >
              <Text
                // fontSize="xl"
                borderBottom="2px"
                fontWeight='normal'
                borderBottomColor="primary.500"
              >
                {'BACK'}
              </Text>
            </Button>

      </Box>
    </>
  );
}

export default BackButton;
