// import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  // query,
  // where,
  // orderBy,
  // limit,
  // startAfter,
} from 'firebase/firestore';
import {
  Center,
  // Text,
  Divider,
  // IconButton,
  Spinner,
  TableContainer,
  Table,
  Tr,
  Td,
  Thead,
  Th,
  Tbody,
  // HStack,
  Heading,
  // Input,
  // InputGroup,
  Textarea,
  // InputRightElement,
  // InputLeftElement,
} from '@chakra-ui/react';
// import { SearchIcon } from '@chakra-ui/icons';
// import { IoIosRefresh } from 'react-icons/io';

// import ReactJson from 'react-json-view';
import moment from 'moment';

import AppBody from '../components/AppBody';
// import { db } from '../constants';

export default function Requests() {
  // const [logs, setLogs] = useState(null);
  const [filteredLogs, setFilteredLogs] = useState(null);
  // const [changed, setChanged] = useState(false);
  // const inputReference = useRef(null);

  const sortArray = array => {
    // descending order of timestamp
    const sortedArray = array.sort((a, b) => b.timestamp - a.timestamp);
    return sortedArray;
  };

  useEffect(() => {
    const getEntries = async () => {
      setFilteredLogs(null);
      try {
        const entries = [];
  
        // const colRef = collection(db, 'logs');
        // const docsSnap = await getDocs(colRef);
        // if (docsSnap.docs.length > 0) {
        //   docsSnap.forEach(doc => {
        //     entries.push({
        //       ...doc.data(),
        //       timestamp: doc.id,
        //     });
        //   });
        // }
  
        // setLogs(sortArray(entries));
        setFilteredLogs(sortArray(entries));
        return;
      } catch (error) {
        console.log(error);
        window.alert('fetch error');
      }
    };


    getEntries();
  }, []);


  // const fetchEntries = async () => {
  //   setFilteredLogs(null);
  //   try {
  //     const entries = [];

  //     // const colRef = collection(db, 'logs');
  //     // const docsSnap = await getDocs(colRef);
  //     // if (docsSnap.docs.length > 0) {
  //     //   docsSnap.forEach(doc => {
  //     //     entries.push({
  //     //       ...doc.data(),
  //     //       timestamp: doc.id,
  //     //     });
  //     //   });
  //     // }

  //     setLogs(sortArray(entries));
  //     setFilteredLogs(sortArray(entries));
  //     return;
  //   } catch (error) {
  //     console.log(error);
  //     window.alert('fetch error');
  //   }
  // };
  
  // const filterArray = (array, filterValue) => {
  //   const filteredArray = array.filter(
  //     item =>
  //       item?.to.toLowerCase().includes(filterValue.toLowerCase()) ||
  //       item?.from.toLowerCase().includes(filterValue.toLowerCase())
  //   );
  //   return sortArray(filteredArray);
  // };
  // const handleFilter = e => {
  //   let inputValue = '';
  //   if (e?.target) {
  //     inputValue = e.target.value;
  //   }
  //   const filtered = filterArray(logs, inputValue);
  //   setFilteredLogs(filtered);
  // };

  // const handleClear = () => {
  //   inputReference.current.value = '';
  //   handleFilter();
  // };
  // const onClickSaveChanges = () => {
  //   if (!window.confirm(`Save changes and overwrite database, are you sure?`)) {
  //     return;
  //   }
  //   updateDoc(userRef, userData);
  //   setChanged(false);
  // };

  // const onChangeObj = obj => {
  //   setLogs(obj);
  //   setChanged(true);
  // };

  if (!filteredLogs)
    return (
      <AppBody>
        <Center>
          <Spinner size="xl" my={'10%'} />
        </Center>
      </AppBody>
    );

  return (
    <AppBody>
        <Heading size="lg">Verify Links sent to customers</Heading>
        <Divider borderColor="primary.500" borderWidth={1} />

      {/* <HStack justify="space-between">
        <Heading size="md" mx={3}>
          <IconButton
            size="sm"
            mr={3}
            aria-label="Refresh"
            onClick={fetchEntries}
            icon={<IoIosRefresh />}
          />
          Verify Links sent to customers
        </Heading>
        <InputGroup w="70%">
          <InputLeftElement children={<SearchIcon color="grey" />} />
          <Input
            onChange={handleFilter}
            ref={inputReference}
            placeholder="Search by From or To"
          />
          <InputRightElement>
            <Button variant="ghost" onClick={handleClear}>
              X
            </Button>
          </InputRightElement>
        </InputGroup>
      </HStack> */}
      {/* {changed && (
        <Button w="full" colorScheme={'primary'} onClick={onClickSaveChanges}>
          SAVE CHANGES
        </Button>
      )}   */}
      <TableContainer mt={10}>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>From</Th>
              <Th>To</Th>
              <Th>message</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredLogs.map((log, i) => {
              return (
                <Tr key={i}>
                  <Td>
                    {moment(log.date, 'ddd MMM DD YYYY kk:mm:ss Z ZZ').format(
                      'YYYY/MM/DD - h:mma'
                    )}
                  </Td>
                  <Td>{log.from}</Td>
                  <Td>{log.to}</Td>
                  <Td w="50%">
                    <Textarea size="sm" rows={3} value={log.message} readOnly />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {/* <ReactJson
        src={logs}
        enableClipboard={true}
        displayObjectSize={false}
        displayDataTypes={false}
        sortKeys={true}
        collapsed={2}
        name={'LOG ENTRIES'}
        // onEdit={e => onChangeObj(e.updated_src)}
        /> */}
    </AppBody>
  );
}
